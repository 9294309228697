import React, { useState } from "react";

import Botao, { BotaoAdicionar, BotaoDeletar } from "../../../components/Botao";
import Loader from "../../../components/Loader";
import api from "../../../service/api";
import { isEmpty } from "../../../components/Util";

const Matematica = ({
  matematica,
  setor,
  atividade,
  formaRisco,
  carregarAmbientes,
  isQuimico,
  riscosSaude,
  isAmbiente,
  setCarregandoAmbientes,
  setMostrarFuncoes,
}) => {
  const [carregandoMatematica, setCarregandoMatematica] = useState(false);
  const matematicaAmbiente = {};

  function preecherMatematicaAmbiente(evento) {
    var nome = evento.target.name;
    var valor = evento.target.value;
    if (nome === "risco_saude_id") {
      matematicaAmbiente.risco_saude_id = valor;
    }
    if (nome === "comprometimento_saude") {
      matematicaAmbiente.comprometimento_saude = valor;
    }
    if (nome === "grau_exposicao") {
      matematicaAmbiente.grau_exposicao = valor;
    }
    if (nome === "efeito_saude") {
      matematicaAmbiente.efeito_saude = valor;
    }
    if (nome === "potencial_risco") {
      matematicaAmbiente.potencial_risco = valor;
    }
    if (nome === "nota") {
      matematicaAmbiente.nota = valor;
    }
  }

  async function cadastrarMatematica(evento, dados) {
    evento.preventDefault();
    if (isAmbiente) {
      setCarregandoAmbientes(true);
      await api.post(`gro/pgr/matematica/matematica/inserirMatematicaAmbiente/${formaRisco.id}`, dados).then(() => {
        carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id);
        setCarregandoAmbientes(false);
      });
    } else {
      setCarregandoMatematica(true);
      await api
        .post(`gro/pgr/matematica/matematica/inserirMatematica/${isEmpty(matematica) ? null : matematica}`, {
          tarefa_id: dados.tarefa_id,
          local_quadro_id: dados.local_quadro_id,
          forma_risco_id: formaRisco.id,
          produto_quimico_id: dados.produto_quimico_id ? dados.produto_quimico_id : null,
        })
        .then(() => {
          carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id);
          setCarregandoMatematica(false);
        })
        .catch((erro) => {
          console.log(erro);
          alert(erro);
          setCarregandoMatematica(false);
        });
    }
  }

  async function editarMatematica(evento, matematica, quimico) {
    isAmbiente ? setCarregandoAmbientes(true) : setCarregandoMatematica(true);
    var nome = evento.target.name;
    var valor = evento.target.value;
    await api
      .put(`/ppra/matematica/editarMatematica/${matematica.id}`, {
        [nome]: valor,
      })
      .then(() => {
        carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id);
        isAmbiente ? setCarregandoAmbientes(false) : setCarregandoMatematica(false);
      })
      .catch((erro) => {
        console.log(erro);
        alert(erro);
        isAmbiente ? setCarregandoAmbientes(false) : setCarregandoMatematica(false);
      });
  }

  async function deletarMatematica(dado) {
    if (window.confirm("Tem certeza que deseja excluir a matemática selecionada?")) {
      isAmbiente ? setCarregandoAmbientes(true) : setCarregandoMatematica(true);
      await api
        .delete(
          isAmbiente
            ? `/ppra/matematica/deletarMatematicasAmbiente/${dado[0].local_quadro_id}/${formaRisco.id}`
            : `/gro/pgr/matematica/Matematica/deletarMatematica/${dado.id}`
        )
        .then(() => {
          carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id);
          isAmbiente ? setCarregandoAmbientes(false) : setCarregandoMatematica(false);
        })
        .catch((erro) => {
          console.log(erro);
          alert(erro);
          isAmbiente ? setCarregandoAmbientes(false) : setCarregandoMatematica(false);
        });
    }
  }

  if (carregandoMatematica) {
    return (
      <td colSpan="7">
        <Loader />
      </td>
    );
  }

  const SelectRiscoSaude = () => {
    const Riscos = ({ matematica }) => {
      return matematica?.riscos_cadastrados?.map((risco, index) =>
      (
        matematica?.riscos_cadastrados.length === (index + 1) ? (
          <>{risco.adjetivo + " " + risco.risco + "."}</>
        ) : (
          <>{risco.adjetivo + " " + risco.risco + ", "}</>
        )
      )
      );
    };

    return isEmpty(matematica?.risco_id !== 2) ? (
      isEmpty(matematica?.riscos_cadastrados) ? (
        isEmpty(matematica?.efeito_saude) ? (
          <>
            <div className="text-center">{" - "}</div>
          </>
        ) : (
          <>
            <select
              className="form-control form-control-sm"
              name="risco_saude_id"
              id={
                "select-riscoSaude-" + matematica
                  ? isQuimico
                    ? atividade.produto_quimico_id
                    : atividade.tarefa_id
                  : atividade.local_quadro_id
              }
              onChange={(e) =>
                matematica
                  ? editarMatematica(e, matematica)
                  : isAmbiente
                  ? preecherMatematicaAmbiente(e)
                  : preecherMatematicaAmbiente(e)
              }
              defaultValue={matematica && matematica.risco_saude_id}
              disabled={matematica}
            >
              <option>-</option>
              {riscosSaude.map((riscoSaude) => (
                <option
                  key={riscoSaude.id}
                  value={riscoSaude.id}
                  disabled={matematica && matematica.risco_saude_id === riscoSaude.id}
                >
                  {riscoSaude.opcao}
                </option>
              ))}
            </select>
          </>
        )
      ) : (
        <>
          <Riscos matematica={matematica} riscosSaude={riscosSaude} />
        </>
      )
    ) : (
      <>
        <select
          className="form-control form-control-sm"
          name="risco_saude_id"
          id={
            "select-riscoSaude-" + matematica
              ? isQuimico
                ? atividade.produto_quimico_id
                : atividade.tarefa_id
              : atividade.local_quadro_id
          }
          onChange={(e) =>
            matematica
              ? editarMatematica(e, matematica)
              : isAmbiente
              ? preecherMatematicaAmbiente(e)
              : preecherMatematicaAmbiente(e)
          }
          defaultValue={matematica && matematica.risco_saude_id}
          disabled={matematica}
        >
          <option>-</option>
          {riscosSaude.map((riscoSaude) => (
            <option
              key={riscoSaude.id}
              value={riscoSaude.id}
              disabled={matematica && matematica.risco_saude_id === riscoSaude.id}
            >
              {riscoSaude.opcao}
            </option>
          ))}
        </select>
      </>
    );
  };

  const SelectGrauExposicao = () => {
    return isEmpty(matematica && matematica.grau_exposicao) ? (
      <div className="text-center">{" - "}</div>
    ) : (
      <div className="text-center">{matematica && matematica.probabilidade}</div>
    );
  };

  const SelectEfeitoSaude = () => {
    return isEmpty(matematica && matematica.efeito_saude) ? (
      <div className="text-center">{" - "}</div>
    ) : (
      <div className="text-center">{matematica && matematica.severidade}</div>
    );
  };

  const SelectPotencialRisco = () => {
    function selecionarCor(resultado) {
      let cor = "";
      let text = "#262626";
      switch (resultado) {
        case "1":
          // Azul
          cor = "#0099DD";
          break;
        case "2":
          //verde
          cor = "#04D939";
          break;
        case "3":
          //amarelo
          cor = "#EAF205";
          break;
        case "4":
          //laranja
          cor = "#F28705";
          break;
        case "5":
          //vermelho
          cor = "#BF3604";
          break;
        default:
          cor = "black";
      }

      return { backgroundColor: cor, color: text };
    }

    function selecionarResultado(resultado) {
      let texto = "";
      switch (resultado) {
        case "1":
          // Azul
          texto = "Insignificante";
          break;
        case "2":
          //verde
          texto = "Leve";
          break;
        case "3":
          //amarelo
          texto = "Moderado";
          break;
        case "4":
          //laranja
          texto = "Alto";
          break;
        case "5":
          //vermelho
          texto = "Grave";
          break;
        default:
          texto = "-";
      }

      return texto;
    }
    return isEmpty(matematica && matematica.potencial_risco) ? (
      <div className="text-center">{" - "}</div>
    ) : (
      <div className="text-center">
        <span className="badge ms-1" style={selecionarCor(matematica.potencial_risco)}>
          {selecionarResultado(matematica.potencial_risco)}
        </span>
      </div>
    );
  };

  const SelectNota = () => {
    const options = [
      { id: "2", opcao: "Não necessário" },
      { id: "3", opcao: "Manter medida de controle existente" },
    ]

    if (atividade.matematica?.potencial_risco >= 3 || !isEmpty(atividade.matematica?.epi_ensaio_vedacao)) {
      options.unshift({ id: "1", opcao: `Ver Plano de Ação (Nota ${formaRisco.definicao})` })
    }

    return (
      <>
        <select
          className="form-control form-control-sm"
          name="nota"
          id={
            "select-nota-" + matematica
              ? isQuimico
                ? atividade.produto_quimico_id
                : atividade.tarefa_id
              : atividade.local_quadro_id
          }
          onChange={(e) =>
            matematica
              ? editarMatematica(e, matematica)
              : isAmbiente
              ? preecherMatematicaAmbiente(e)
              : preecherMatematicaAmbiente(e)
          }
          defaultValue={matematica && matematica.nota}
          disabled={isAmbiente && atividade.quantidade_matematicas > 0}
        >
          <option>-</option>
          {options.map((nota) => (
            <option key={nota.id} value={nota.opcao} disabled={matematica && matematica.nota === nota.opcao}>
              {nota.opcao}
            </option>
          ))}
        </select>
      </>
    );
  };

  function atualizarMatematicaAmbientes(localQuadroId, formaRiscoId) {
    setCarregandoAmbientes(true)
    api.get(`gro/pgr/matematica/matematica/atualizarMatematicaAmbiente/${localQuadroId}/${formaRiscoId}`).then(() => {
      carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id);
      setCarregandoAmbientes(false)
    })
  }
  
  return (
    <>
      <td colSpan={isAmbiente ? 6 : 1}>
        {isAmbiente ? (
          <div className="row justify-content-center">
            {atividade.quantidade_matematicas === 0 && (
              <div className="col-auto">
                <Botao
                  cor="success"
                  icone="plus"
                  conteudo="Cadastrar"
                  title="Cadastrar todas as matemáticas do ambiente"
                  onClick={(e) => cadastrarMatematica(e, atividade)}
                />
              </div>
            )}

            {atividade.quantidade_matematicas !== 0 && (
              <>
                <div className="col-auto">
                  <Botao
                    cor="success"
                    icone="plus"
                    conteudo="Cadastrar"
                    title="Cadastrar todas as matemáticas faltantes do ambiente"
                    onClick={(e) => cadastrarMatematica(e, atividade)}
                    disabled={isQuimico ? (atividade.quantidade_matematicas === atividade.quimicos.length) ? true : false : (atividade.quantidade_matematicas === atividade.atividades.length) ? true : false}
                  />
                </div>
                <div className="col-auto">
                  <Botao
                    cor="warning"
                    icone="refresh"
                    conteudo="Atualizar matemáticas"
                    onClick={() => atualizarMatematicaAmbientes(atividade.local_quadro_id, formaRisco.id)}
                    title="Irá atualizar os dados da matemática"
                  />
                </div>
              </>
            )}

            {atividade.quantidade_matematicas > 0 && (
              <div className="col-auto">
                <Botao
                  icone="trash"
                  cor="danger"
                  title="Deletar todas matemáticas do ambiente"
                  conteudo="Deletar"
                  onClick={() => deletarMatematica(isQuimico ? atividade.quimicos : atividade.atividades)}
                />
              </div>
            )}
          </div>
        ) : isQuimico ? (
          isEmpty(atividade.matematica?.riscos_cadastrados) ? (
            atividade.risco_saude
          ) : (
            <SelectRiscoSaude />
          )
        ) : (
          <SelectRiscoSaude />
        )}
      </td>
      {/* <td>
        <SelectComprometimentoSaude />
      </td> */}
      {!isAmbiente && (
        <>
          <td>
            <SelectGrauExposicao />
          </td>
          <td>
            <SelectEfeitoSaude />
          </td>
          <td>
            <SelectPotencialRisco />
          </td>
          <td>
            <SelectNota />
          </td>
          <td className="text-center">
            {isAmbiente ? (
              atividade.quantidade_matematicas > 0 ? (
                <BotaoDeletar onClick={() => deletarMatematica(isQuimico ? atividade.quimicos : atividade.atividades)} />
              ) : (
                <BotaoAdicionar onClick={(e) => cadastrarMatematica(e, atividade)} />
              )
            ) : atividade.matematica ? (
              <BotaoDeletar
                onClick={() => {
                  deletarMatematica(matematica);
                  setMostrarFuncoes(false);
                }}
              />
            ) : (
              <BotaoAdicionar onClick={(e) => cadastrarMatematica(e, atividade, matematica)} />
            )}
          </td>
        </>
      )}
    </>
  );
};

export default Matematica;
