import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import ModalPadrao from "../../../../components/ModalPadrao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { filtrar, isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";
import Filtros from "../../../ppra/components/Filtros";
import { BotaoAdicionar, BotaoRemover } from "../../../../components/Botao";

const ModalAcidentePerigoMedidaControleNecessaria = ({
  show,
  setShow,
  tituloSpan,
  titulo,
  corTitulo,
  perigoMedidaControleNecessaria,
  buscarPerigosMedidaControleNecessaria,
}) => {
  const [medidasControleNecessarias, setMedidasControleNecessarias] = useState();
  const [filtro, setFiltro] = useState("");
  const [filtroMedida, setFiltroMedida] = useState("");
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    api
      .get(`/baseDados/acidenteBaseDados/obterPerigoMedidaNecessariaVinculo/${perigoMedidaControleNecessaria.id}`)
      .then((resposta) => {
        setMedidasControleNecessarias(resposta.data);
        setCarregando(false);
      });
  }, [perigoMedidaControleNecessaria.id]);

  async function buscarDados() {
    setCarregando(true);
    await api
      .get(`/baseDados/acidenteBaseDados/obterPerigoMedidaNecessariaVinculo/${perigoMedidaControleNecessaria.id}`)
      .then((resposta) => {
        setMedidasControleNecessarias(resposta.data);
        setCarregando(false);
      });
  }

  const medidasFiltradas = medidasControleNecessarias?.cadastrados?.filter((medida) =>
    filtrar(medida.codigo + " " + medida.definicao, filtro)
  );

  const medidasNaoCadastradasFiltradas = medidasControleNecessarias?.nao_cadastrados?.filter((medida) =>
    filtrar(medida.codigo + " " + medida.definicao, filtroMedida)
  );

  const opcoesResultadoMatematica = [
    { value: 1, label: "1 - Insignificante" },
    { value: 2, label: "2 - Leve" },
    { value: 3, label: "3 - Moderado" },
    { value: 4, label: "4 - Alto" },
    { value: 5, label: "5 - Grave" },
  ];

  const verificarResultadosMatematicas = (resultadoMatematica) => {
    return isEmpty(resultadoMatematica)
      ? { value: 0, label: "" }
      : opcoesResultadoMatematica.find((opcao) => opcao.value === parseInt(resultadoMatematica, 10));
  };

  async function handleAdiciona(perigoId, medidaControleNecessariaId) {
    await api
      .post("/baseDados/acidenteBaseDados/inserirPerigoMedidaControleNecessaria", {
        perigo_acidente_id: perigoId,
        medida_controle_necessaria_acidente_id: medidaControleNecessariaId,
      })
      .then(() => {
        buscarDados();
        buscarPerigosMedidaControleNecessaria();
        toast.success("Medida de controle vinculada!");
      });
  }

  async function handleEdita(medidaControleNecessariaId, resultadoMatematica) {
    await api
      .put(`/baseDados/acidenteBaseDados/editarPerigoMedidaControleNecessariaVinculo/${medidaControleNecessariaId}`, {
        resultado_matematica: resultadoMatematica,
      })
      .then(() => {
        buscarDados();
        buscarPerigosMedidaControleNecessaria();
        toast.success("Resultado editado com sucesso!");
      });
  }

  async function handleDeleta(perigoMedidaControleNecessariaId) {
    await api
      .delete(`/baseDados/acidenteBaseDados/deletarPerigoMedidaControleNecessaria/${perigoMedidaControleNecessariaId}`)
      .then(() => {
        buscarDados();
        buscarPerigosMedidaControleNecessaria();
        toast.success("Medida de controle necessária desvinculada!");
      });
  }

  function determinarCorResultadoMatematica(resultado) {
    switch (parseInt(resultado, 10)) {
      case 1:
        return "#0099DD"; // Azul
      case 2:
        return "#04D939"; // Verde
      case 3:
        return "#EAF205"; // Amarelo
      case 4:
        return "#F28705"; // Laranja
      case 5:
        return "#BF3604"; // Vermelho
      default:
        return "#FFFFFF"; // Cor padrão
    }
  }

  return (
    <ModalPadrao
      show={show}
      setShow={setShow}
      tituloSpan={tituloSpan}
      titulo={titulo}
      corTitulo={corTitulo}
      tamanho="xl"
      modalCentralizada
    >
      <div className="row">
        <div style={{ fontSize: "18px" }} className="col ms-2">
          <span className="badge bg-primary">Perigo</span>{" "}
          {perigoMedidaControleNecessaria.codigo + " - " + perigoMedidaControleNecessaria.definicao}
        </div>
      </div>
      <Filtros
        itens={[
          { placeholder: "Pesquisar medidas de controle não cadastradas", filtrar: setFiltroMedida },
          { placeholder: "Pesquisar medidas de controle", filtrar: setFiltro },
        ]}
      />
      <div className="row">
        <div className="col">
          <TabelaPadrao
            colunas={[
              { nome: "Código", width: "8%", text: "center" },
              { nome: "Grupo Medida", width: "10%", text: "center" },
              { nome: "Medidas de Controle Necessária", width: "70%" },
              { nome: "Ações", width: "8%", text: "center" },
            ]}
            itens={medidasNaoCadastradasFiltradas}
            carregando={carregando}
          >
            {medidasNaoCadastradasFiltradas?.map((medidaControleNecessarias) => (
              <tr key={medidaControleNecessarias.id}>
                <td className="text-center align-middle">{medidaControleNecessarias.codigo}</td>
                <td className="text-center align-middle">{medidaControleNecessarias.grupo}</td>
                <td className="align-middle">{medidaControleNecessarias.definicao}</td>
                <td className="align-middle text-center">
                  <BotaoAdicionar
                    onClick={() => handleAdiciona(perigoMedidaControleNecessaria.id, medidaControleNecessarias.id)}
                  />
                </td>
              </tr>
            ))}
          </TabelaPadrao>
        </div>
        <div className="col">
          <TabelaPadrao
            colunas={[
              { nome: "Código", width: "8%", text: "center" },
              { nome: "Grupo Medida", width: "10%", text: "center" },
              { nome: "Medidas de Controle Necessária", width: "60%" },
              { nome: "A partir de (Matemática)", width: "40%", text: "center" },
              { nome: "Ações", width: "8%", text: "center" },
            ]}
            itens={medidasFiltradas}
            carregando={carregando}
          >
            {medidasFiltradas?.map((medidaControleNecessarias) => (
              <tr className={isEmpty(medidaControleNecessarias.resultado_matematica) && "table-warning"} key={medidaControleNecessarias.id}>
                <td className="text-center align-middle">{medidaControleNecessarias.codigo}</td>
                <td className="text-center align-middle">{medidaControleNecessarias.grupo}</td>
                <td className="align-middle">{medidaControleNecessarias.definicao}</td>
                <td className="align-middle">
                  <Select
                    id="resultadoMatematica"
                    name="resultado_matematica_id"
                    defaultValue={verificarResultadosMatematicas(medidaControleNecessarias.resultado_matematica)}
                    onChange={(option) => {
                      handleEdita(medidaControleNecessarias.id, option.value);
                    }}
                    options={opcoesResultadoMatematica}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: determinarCorResultadoMatematica(
                          medidaControleNecessarias.resultado_matematica
                        ),
                      }),
                    }}
                    required
                  />
                </td>
                <td className="align-middle text-center">
                  <BotaoRemover onClick={() => handleDeleta(medidaControleNecessarias.id)} />
                </td>
              </tr>
            ))}
          </TabelaPadrao>
        </div>
      </div>
    </ModalPadrao>
  );
};

export default ModalAcidentePerigoMedidaControleNecessaria;
