import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import ModalPadrao from "../../../../components/ModalPadrao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import api from "../../../../service/api";
import { filtrar } from "../../../../components/Util";
import Filtros from "../../../ppra/components/Filtros";
import { BotaoAdicionar, BotaoRemover } from "../../../../components/Botao";

const ModalPerigoRisco = ({ show, setShow, tituloSpan, titulo, corTitulo, perigoRisco, buscarPerigosRiscos }) => {
  const [riscos, setRiscos] = useState();
  const [carregando, setCarregando] = useState(false);
  const [filtroRisco, setFiltroRisco] = useState("");
  const [filtroRiscoNaoCadastrado, setFiltroRiscoCadastrado] = useState("");
  const perigoRiscoId = perigoRisco.id;

  useEffect(() => {
    buscarRiscos(perigoRiscoId);
  }, [perigoRiscoId]);

  async function buscarRiscos(perigoId) {
    setCarregando(true);
    await api
      .get(`/baseDados/ergonomiaBaseDados/buscarPerigoRiscos/${perigoId}`)
      .then((resposta) => {
        setRiscos(resposta.data);
        setCarregando(false);
      })
      .catch((e) => console.log(e));
  }

  const riscoCadastrados = riscos?.cadastrados?.filter((risco) => filtrar(risco.riscos, filtroRisco));

  function filtrarRiscoCadastrado(newFilter) {
    setFiltroRiscoCadastrado(newFilter);
  }

  const riscoCadastradosNaoCadastrados = riscos?.nao_cadastrados?.filter((risco) =>
    filtrar(risco.riscos, filtroRiscoNaoCadastrado)
  );

  function filtrarRiscoNaoCadastrado(newFilter) {
    setFiltroRisco(newFilter);
  }

  async function adicionarPerigoRisco(riscoId, perigoId) {
    await api
      .post("/baseDados/ergonomiaBaseDados/inserirPerigoRisco", {
        perigo_definicao_id: perigoId,
        riscos_ergonomia_id: riscoId,
      })
      .then(() => {
        buscarRiscos(perigoRiscoId);
        toast.success("Risco vinculado!");
      })
      .catch((erro) => {
        alert(erro.response.data.mensagem);
      });
  }

  async function removerPerigoRisco(risco) {
    await api
      .delete(`/baseDados/ergonomiaBaseDados/deletarPerigoRisco/${risco.perigoRiscoId}`)
      .then(() => {
        buscarRiscos(perigoRiscoId);
        toast.success("Risco desvinculado!");
      })
      .catch((erro) => {
        alert(erro.response.data.mensagem);
      });
  }

  return (
    <>
      <ModalPadrao
        show={show}
        setShow={setShow}
        tituloSpan={tituloSpan}
        titulo={titulo}
        corTitulo={corTitulo}
        tamanho="xl"
        modalCentralizada
        handleHide={() => buscarPerigosRiscos()}
      >
        <Filtros
          itens={[
            {
              placeholder: "Riscos não adicionados",
              filtrar: filtrarRiscoNaoCadastrado,
              carregando: carregando,
            },
            {
              placeholder: "Riscos adicionados",
              filtrar: filtrarRiscoCadastrado,
              carregando: carregando,
            },
          ]}
        />
        <div className="row">
          <div className="col">
            <TabelaPadrao
              colunas={[
                { nome: "Nº", width: "5%", text: "center" },
                { nome: "Riscos", width: "80%" },
                { nome: "Ações", width: "8%", text: "center" },
              ]}
              itens={riscos}
              carregando={carregando}
            >
              {riscoCadastradosNaoCadastrados?.map((risco) => (
                <tr key={risco.id}>
                  <td className="text-center align-middle">{risco.codigo}</td>
                  <td className="align-middle">{risco.riscos}</td>
                  <td className="text-center align-middle">
                    <BotaoAdicionar
                      onClick={() => {
                        adicionarPerigoRisco(risco.id, perigoRisco.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </TabelaPadrao>
          </div>
          <div className="col">
            <TabelaPadrao
              colunas={[
                { nome: "Nº", width: "5%", text: "center" },
                { nome: "Riscos", width: "80%" },
                { nome: "Ações", width: "8%", text: "center" },
              ]}
              itens={riscos}
              carregando={carregando}
            >
              {riscoCadastrados?.map((risco) => (
                <tr key={risco.id}>
                  <td className="text-center align-middle">{risco.codigo}</td>
                  <td className="align-middle">{risco.riscos}</td>
                  <td className="text-center align-middle">
                    <BotaoRemover onClick={() => removerPerigoRisco(risco)} />
                  </td>
                </tr>
              ))}
            </TabelaPadrao>
          </div>
        </div>
      </ModalPadrao>
    </>
  );
};

export default ModalPerigoRisco;
