import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalPadrao from "../../../../components/ModalPadrao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import api from "../../../../service/api";
import { BotaoAdicionar, BotaoRemover } from "../../../../components/Botao";
import Filtros from "../../../ppra/components/Filtros";
import { filtrar, isEmpty } from "../../../../components/Util";

const ModalAcidentePerigoRisco = ({
  show,
  setShow,
  tituloSpan,
  titulo,
  corTitulo,
  perigoRisco,
  buscarPerigosRiscos,
}) => {
  const [riscos, setRiscos] = useState();
  const [carregando, setCarregando] = useState(false);
  const [filtroCadastrado, setFiltroRiscoCadastrado] = useState('');
  const [filtroNaoCadastrado, setFiltroRiscoNaoCadastrado] = useState('')

  const perigoId = perigoRisco.id;

  useEffect(() => {
    buscarRiscos(perigoId);
  }, [perigoId]);

  async function buscarRiscos(perigoId) {
    setCarregando(true);
    await api
      .get(`/baseDados/acidenteBaseDados/obterPerigoRiscos/${perigoId}`)
      .then((resposta) => {
        setRiscos(resposta.data);
        setCarregando(false);
      })
      .catch((e) => console.log(e));
  }

  //filtra os riscos cadastrados conforme oque foi digitado em filtro
  const riscoCadastrados = riscos?.cadastrados?.filter((risco) => filtrar(risco.codigo + " " + risco.definicao, filtroCadastrado));

  //Salva oque foi digitado em filtro cadastrados
  function filtrarRiscoCadastrado(newFilter) {
    setFiltroRiscoCadastrado(newFilter);
  }

  //filtra os riscos nao cadastrados conforme oque foi digitado em filtro nao cadastrado
  const riscoNaoCadastrados = riscos?.nao_cadastrados?.filter((risco) =>
    filtrar(risco.codigo + " " + risco.definicao, filtroNaoCadastrado)
  );

  //Salva oque foi digitado em filtro não cadastrados
  function filtrarRiscoNaoCadastrado(newFilter) {
    setFiltroRiscoNaoCadastrado(newFilter);
  }

  async function adicionarPerigoRisco(riscoId, perigoId) {
    await api
      .post("/baseDados/acidenteBaseDados/inserirPerigoRisco", {
        perigo_acidente_id: perigoId,
        risco_acidente_id: riscoId,
      })
      .then(() => {
        buscarRiscos(perigoId);
        toast.success("Risco cadastrado!");
      })
      .catch((erro) => {
        alert(erro.response.data.mensagem);
      });
  }

  async function removerPerigoRisco(perigoRiscoId) {
    await api
      .delete(`/baseDados/acidenteBaseDados/deletarPerigoRisco/${perigoRiscoId}`)
      .then(() => {
        buscarRiscos(perigoId);
        toast.success("Risco removido!");
      })
      .catch((erro) => {
        alert(erro.response.data.mensagem);
      });
  }

  return (
    <>
      <ModalPadrao
        show={show}
        setShow={setShow}
        tituloSpan={tituloSpan}
        titulo={titulo}
        corTitulo={corTitulo}
        tamanho="xl"
        modalCentralizada
        handleHide={() => buscarPerigosRiscos()}
      >
        <Filtros
          itens={[
            {
              placeholder: "Riscos não adicionados",
              filtrar: filtrarRiscoNaoCadastrado,
              carregando: carregando,
            },
            {
              placeholder: "Riscos adicionados",
              filtrar: filtrarRiscoCadastrado,
              carregando: carregando,
            },
          ]}
        />
        <div className="row">
          <div className="col">
            <TabelaPadrao
              colunas={[
                { nome: "Nº", width: "5%", text: "center" },
                { nome: "Riscos", width: "67%" },
                { nome: "Severidade", width: "20%", text: "center" },
                { nome: "Ações", width: "8%", text: "center" },
              ]}
              itens={riscoNaoCadastrados}
              itensFiltrados={riscoNaoCadastrados}
              carregando={carregando}
            >
              {riscoNaoCadastrados?.map((risco) => (
                <tr key={risco.id}>
                  <td className="text-center align-middle">{risco.codigo}</td>
                  <td className="align-middle">{risco.definicao}</td>
                  <td className="text-center align-middle">{isEmpty(risco.severidade) ? "-" : risco.severidade}</td>
                  <td className="text-center align-middle">
                    <BotaoAdicionar
                      onClick={() => {
                        adicionarPerigoRisco(risco.id, perigoRisco.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </TabelaPadrao>
          </div>
          <div className="col">
            <TabelaPadrao
              colunas={[
                { nome: "Nº", width: "5%", text: "center" },
                { nome: "Riscos", width: "67%" },
                { nome: "Severidade", width: "20%", text: "center" },
                { nome: "Ações", width: "8%", text: "center" },
              ]}
              itens={riscoCadastrados}
              itensFiltrados={riscoCadastrados}
              carregando={carregando}
            >
              {riscoCadastrados?.map((risco) => (
                <tr key={risco.id}>
                  <td className="text-center align-middle">{risco.codigo}</td>
                  <td className="align-middle">{risco.definicao}</td>
                  <td className="text-center align-middle">{isEmpty(risco.severidade) ? "-" : risco.severidade}</td>
                  <td className="text-center align-middle">
                    <BotaoRemover onClick={() => removerPerigoRisco(risco.perigoRiscoId)} />
                  </td>
                </tr>
              ))}
            </TabelaPadrao>
          </div>
        </div>
      </ModalPadrao>
    </>
  );
};

export default ModalAcidentePerigoRisco;
