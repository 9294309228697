import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ModalSubmit } from "../../../../components/ModalPadrao";
import { isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";
import Icone from "../../../../components/Icone";

const ModalMedidaControleNecessaria = ({
  show,
  setShow,
  titulo,
  corTitulo,
  medidaControleNecessaria,
  buscarMedidasControleNecessaria,
  novaMedidaControleNecessaria,
  proximoCodigo,
}) => {
  const [medidaTipo, setMedidaTipo] = useState([]);
  const [formasAcompanhamento, setFormasAcompanhmanto] = useState([]);
  const [salvarCodigo, setSalvarCodigo] = useState(medidaControleNecessaria ? medidaControleNecessaria.codigo : proximoCodigo);
  const [salvarMedidaControleNecessaria, setSalvarMedidaControleNecessaria] = useState(
    medidaControleNecessaria ? medidaControleNecessaria.definicao : novaMedidaControleNecessaria
  );
  const [salvarTipoMedidaControleNecessaria, setSalvarTipoMedidaControleNecessaria] = useState(
    medidaControleNecessaria?.tipo_medida_controle_id
  );
  const [salvarPrazoMedidaControleNecessaria, setSalvarPrazoMedidaControleNecessaria] = useState(medidaControleNecessaria?.prazo);
  const [salvarFormaAcompnhamentoMedidaControleNecessaria, setSalvarFormaAcompnhamentoMedidaControleNecessaria] = useState(
    medidaControleNecessaria?.forma_acompanhamento_id
  );
  const [aetTipo, setAetTipo] = useState(medidaControleNecessaria?.aet_tipo);

  useEffect(() => {
    buscarMedidasControleGrupo();
    buscarFormasAcompanhamento();
  }, []);

  async function buscarMedidasControleGrupo() {
    await api.get("/baseDados/ergonomiaBaseDados/obterMedidasControleGrupo").then((resposta) => {
      setMedidaTipo(resposta.data);
    });
  }

  async function buscarFormasAcompanhamento() {
    await api.get("/baseDados/ergonomiaBaseDados/obterFormasAcompanhamento").then((resposta) => {
      setFormasAcompanhmanto(resposta.data);
    });
  }

  async function salvarBanco(e) {
    e.preventDefault();
    if (isEmpty(medidaControleNecessaria)) {
      api
        .post("/baseDados/ergonomiaBaseDados/inserirMedidaControleNecessaria", {
          codigo: salvarCodigo,
          definicao: salvarMedidaControleNecessaria,
          forma_acompanhamento_id: salvarFormaAcompnhamentoMedidaControleNecessaria,
          prazo: salvarPrazoMedidaControleNecessaria,
          tipo_medida_controle_id: salvarTipoMedidaControleNecessaria,
          aet_tipo: aetTipo,
        })
        .then(() => {
          buscarMedidasControleNecessaria();
          setShow(false);
        });
    } else {
      api
        .put(`/baseDados/ergonomiaBaseDados/editarMedidaControle`, {
          id: medidaControleNecessaria.id,
          codigo: salvarCodigo,
          definicao: salvarMedidaControleNecessaria,
          forma_acompanhamento_id: salvarFormaAcompnhamentoMedidaControleNecessaria,
          prazo: salvarPrazoMedidaControleNecessaria,
          tipo_medida_controle_id: salvarTipoMedidaControleNecessaria,
          aet_tipo: aetTipo,
        })
        .then(() => {
          buscarMedidasControleNecessaria();
          setShow(false);
        });
    }
  }

  let optionsTipoMedida = medidaTipo.map((item) => ({
    label: item.grupo,
    value: item.id,
  }));

  let optionsFormaAcompanhamento = formasAcompanhamento.map((item) => ({
    label: item.acompanhamento,
    value: item.id,
  }));

  function selecionaTipoMedida(medida_id) {
    if (medida_id === "1") {
      return { label: "EPC" };
    } else if (medida_id === "2") {
      return { label: "EPI" };
    } else if (medida_id === "3") {
      return { label: "ORG" };
    } else {
      return { label: "--" };
    }
  }

  function selecionaFormaAcompanhamento(forma_acompanhamento_id) {
    if (forma_acompanhamento_id === "1") {
      return { label: "Inspeção no local" };
    } else if (forma_acompanhamento_id === "3") {
      return { label: "Análise documental" };
    } else if (forma_acompanhamento_id === "5") {
      return { label: "Ficha EPI" };
    } else if (forma_acompanhamento_id === "7") {
      return { label: "Nenhum" };
    } else if (forma_acompanhamento_id === "11") {
      return { label: "Registro(assinatura)" };
    } else {
      return { label: "--" };
    }
  }

  return (
    <ModalSubmit show={show} setShow={setShow} titulo={titulo} corTitulo={corTitulo} onSubmit={(e) => salvarBanco(e)}>
      <>
        <div className="row">
          <div className="col-auto d-flex align-items-center">
            <label htmlFor="codigo" className="form-label">
              Código:
            </label>
          </div>

          <div className="col-1">
            <input
              type="text"
              id="codigo"
              name="codigo"
              className="form-control"
              defaultValue={medidaControleNecessaria?.codigo ? medidaControleNecessaria?.codigo : salvarCodigo}
              onChange={(m) => {
                setSalvarCodigo(m.target.value);
              }}
              required
            />
          </div>

          <div className="col-auto d-flex align-items-center">
            <label htmlFor="">Medida de controle:</label>
          </div>

          <div className="col">
            <input
              type="text"
              id="medidaControleNecessaria"
              name="medidaControleNecessaria"
              className="form-control"
              defaultValue={medidaControleNecessaria ? salvarMedidaControleNecessaria : novaMedidaControleNecessaria}
              onChange={(m) => {
                setSalvarMedidaControleNecessaria(m.target.value);
              }}
              required
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-3">
            <label htmlFor="tipoMedida">Medida Grupo:</label>
            <Select
              options={optionsTipoMedida}
              onChange={(m) => {
                setSalvarTipoMedidaControleNecessaria(m.value);
              }}
              id="tipoMedida"
              defaultValue={selecionaTipoMedida(medidaControleNecessaria?.tipo_medida_controle_id)}
            />
          </div>

          <div className="col">
            <label htmlFor="formaAcompanhamento">Forma de Acompanhamento:</label>
            <Select
              options={optionsFormaAcompanhamento}
              onChange={(m) => {
                setSalvarFormaAcompnhamentoMedidaControleNecessaria(m.value);
              }}
              id="formaAcompanhamento"
              defaultValue={selecionaFormaAcompanhamento(medidaControleNecessaria?.forma_acompanhamento_id)}
            />
          </div>

          <div className="col">
            <label htmlFor="prazo">Prazo:</label>
            <small className="ml-2 text-muted">{"Número de meses"}</small>
            <input
              type="number"
              id="prazo"
              name="prazo"
              maxlength="2"
              className="form-control"
              defaultValue={medidaControleNecessaria?.prazo}
              onChange={(m) => {
                setSalvarPrazoMedidaControleNecessaria(m.target.value);
              }}
              required
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-2">
            <label htmlFor="">
              AET{" "}
              <Icone
                icone="info-circle"
                className="text-primary"
                title="Selecione qual campo do cabeçalho da AET, deseja ficar marcado"
              />
            </label>
          </div>

          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="opcao"
                id="nenhum"
                value="null"
                defaultChecked={isEmpty(aetTipo) || aetTipo === 'null'}
                onChange={(e) => setAetTipo(e.target.value)}
              />
              <label className="form-check-label" htmlFor="nenhum">
                Nenhum
              </label>
            </div>
          </div>

          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="opcao"
                id="funcao"
                value="funcao"
                defaultChecked={aetTipo === "funcao"}
                onChange={(e) => setAetTipo(e.target.value)}
              />
              <label className="form-check-label" htmlFor="funcao">
                Função
              </label>
            </div>
          </div>

          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="opcao"
                id="funcionario"
                value="funcionario"
                defaultChecked={aetTipo === "funcionario"}
                onChange={(e) => setAetTipo(e.target.value)}
              />
              <label className="form-check-label" htmlFor="funcionario">
                Funcionário
              </label>
            </div>
          </div>

          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="opcao"
                id="atividade"
                value="atividade"
                defaultChecked={aetTipo === "atividade"}
                onChange={(e) => setAetTipo(e.target.value)}
              />
              <label className="form-check-label" htmlFor="atividade">
                Atividades
              </label>
            </div>
          </div>

          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="opcao"
                id="avaliacao"
                value="avaliacao"
                defaultChecked={aetTipo === "avaliacao"}
                onChange={(e) => setAetTipo(e.target.value)}
              />
              <label className="form-check-label" htmlFor="avaliacao">
                Avaliação
              </label>
            </div>
          </div>
        </div>
      </>
    </ModalSubmit>
  );
};

export default ModalMedidaControleNecessaria;
